import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DeviceTableList} from '@app/modules/device/models/device.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';

@Component({
  selector: 'mcon-popup-device',
  templateUrl: './popup-device.component.html',
  styleUrls: ['./popup-device.component.scss']
})
export class PopupDeviceComponent {

  constructor(
    private _snackBar: MatSnackBar,
    private clipboard: Clipboard,
    public dialogRef: MatDialogRef<PopupDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeviceTableList
  ) { }

  copyToClipboard(value: string) {
    this.clipboard.copy(value);
    this._snackBar.openFromComponent(SnackBarComponent, {
      data: 'SNACK_BAR.COPIED',
      panelClass: ["snack-success"]
    });
  }

  closePopup(): void {
    this.dialogRef.close();
  }
}
