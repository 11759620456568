import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/modules/authentication/services/authentication.service';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable()
export class GlobalErrorHandler implements HttpInterceptor {

  constructor(
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
        .pipe(
            map(res => {
                return res;
            }),
            catchError((error: HttpErrorResponse) => {
                if(error.status === 401 || error.error?.code === '607') {
                  this._authenticationService.deleteAccessToken();
                  this._router.navigate(['/login/error']);
                } else if(error.error && error.error?.code === '504') {
                  this._snackBar.openFromComponent(SnackBarComponent, {
                      data: 'SNACK_BAR.ERROR_504',
                      panelClass: ["snack-error"]
                    });
                } else if(error.error && error.error?.code !== '046' && error.error?.code !== '045') {
                  this._snackBar.openFromComponent(SnackBarComponent, {
                    data: error.message,
                    panelClass: ["snack-error"]
                  });
                }
                return throwError(error);
            })
        );
}
}
