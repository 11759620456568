import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '@app/modules/shared/components/footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '@app/modules/material/material.module';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { LocationBackComponent } from './components/location-back/location-back.component';
import { PopupOrganizationComponent } from './components/popup-organization/popup-organization.component';
import { PopupObservationComponent } from './components/popup-observation/popup-observation.component';
import { PopupDeviceComponent } from './components/popup-device/popup-device.component';
import { PopupSecretKeyComponent } from './components/popup-secret-key/popup-secret-key.component';
import { ChipEntitySelectedComponent } from './components/chip-entity-selected/chip-entity-selected.component';
import { MultiSelectSearchComponent } from './components/multi-select-search/multi-select-search.component';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { PopupAuditEventComponent } from './components/popup-audit-event/popup-audit-event.component';
import { PopupErrorCodeLeyendComponent } from './components/popup-error-code-leyend/popup-error-code-leyend.component';
import { PopupServiceRequestComponent } from './components/popup-service-request/popup-service-request.component';
import { InfoCardUserComponent } from './components/info-card-user/info-card-user.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { PopupRefreshCredentialsComponent } from './components/popup-refresh-credentials/popup-refresh-credentials.component';
import { PopupConfirmActionComponent } from './components/popup-confirm-action/popup-confirm-action.component';
import { HorizontalBarChartComponent } from './components/horizontal-bar-chart/horizontal-bar-chart.component';
import { AdvancedPieChartComponent } from './components/advanced-pie-chart/advanced-pie-chart.component';
import { PopupInformationComponent } from './components/popup-information/popup-information.component';

@NgModule({
  declarations: [
    FooterComponent,
    SpinnerComponent,
    SnackBarComponent,
    ToolbarComponent,
    LocationBackComponent,
    PopupOrganizationComponent,
    PopupObservationComponent,
    PopupSecretKeyComponent,
    PopupDeviceComponent,
    ChipEntitySelectedComponent,
    MultiSelectSearchComponent,
    SelectSearchComponent,
    PopupAuditEventComponent,
    PopupServiceRequestComponent,
    PopupErrorCodeLeyendComponent,
    InfoCardUserComponent,
    AutocompleteComponent,
    PopupRefreshCredentialsComponent,
    PopupConfirmActionComponent,
    HorizontalBarChartComponent,
    AdvancedPieChartComponent,
    PopupInformationComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MaterialModule,
    RouterModule,
    TranslateModule
  ],
  exports: [
    MaterialModule,
    FooterComponent,
    SpinnerComponent,
    RouterModule,
    ToolbarComponent,
    LocationBackComponent,
    ChipEntitySelectedComponent,
    MultiSelectSearchComponent,
    SelectSearchComponent,
    InfoCardUserComponent,
    AutocompleteComponent,
    PopupRefreshCredentialsComponent,
    PopupConfirmActionComponent,
    HorizontalBarChartComponent,
    AdvancedPieChartComponent,
    PopupInformationComponent
  ]
})
export class SharedModule { }
