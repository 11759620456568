<mat-card *ngIf="data">
  <div class="column col-12 card-header-container">
      <div>
          <mat-card-title>{{ data.deviceName ? data.deviceName : '-' }}</mat-card-title>
      </div>
  </div>
  <mat-card-content class="column col-12 mt-2">

      <div class="id-container">
        <p>
          <b>{{ 'POPUP_DEVICE.ID' | translate }} </b> {{ data.id ? data.id : '-' }}
        </p>
        <mat-icon class="copy-icon" (click)="copyToClipboard(data.id)" *ngIf="data.id">content_copy</mat-icon>
      </div>

      <ng-container *ngIf="data.status && data.phase == 'Generico'">
        <p *ngIf="data.status == 'active'"> <b>{{ 'POPUP_DEVICE.STATUS' | translate }} </b> {{ 'DEVICE.TABLE.HEADER.ACTIVE' | translate }} </p>
        <p *ngIf="data.status == 'inactive'"> <b>{{ 'POPUP_DEVICE.STATUS' | translate }} </b> {{ 'DEVICE.TABLE.HEADER.INACTIVE' | translate }} </p>
      </ng-container>

      <p>
        <b>{{ 'POPUP_DEVICE.TYPE' | translate }} </b> {{ data.deviceType ? data.deviceType : '-' }}
      </p>
      <p>
        <b>{{ 'POPUP_DEVICE.LAST_UPDATE' | translate }} </b> {{ data.lastUpdated ? (data.lastUpdated | date: "dd/MM/yyyy" : "+0000") : '-' }}
      </p>
      <p>
        <b>{{ 'POPUP_DEVICE.MANUFACTURER' | translate }} </b> {{ data.manufacturer ? data.manufacturer : '-' }}
      </p>

      <p>
        <b>{{ 'POPUP_DEVICE.MANUFACTURER_DATA' | translate }} </b> {{ data.manufactureDate ? (data.manufactureDate | date: "dd/MM/yyyy" : "+0000") : '-' }}
      </p>
      <p>
        <b>{{ 'POPUP_DEVICE.SERIAL_NUMBER' | translate }} </b> {{ data.serialNumber ? data.serialNumber : '-' }}
      </p>

      <p>
        <b>{{ 'POPUP_DEVICE.IDENTIFIER' | translate }} </b> {{ data.modelNumber ? data.modelNumber : '-' }}
      </p>
      <p>
        <b>{{ 'POPUP_DEVICE.LOT_NUMBER' | translate }} </b> {{ data.lotNumber ? data.lotNumber : '-' }}
      </p>
      <p>
        <b>{{ 'POPUP_DEVICE.ACCEPT_OBSERVATIONS' | translate }} </b> {{ data.identifier ? (data.identifier | titlecase) : '-' }}
      </p>
  </mat-card-content>
</mat-card>
<div class="button-container">
  <button mat-button class="btn cancel-button" (click)="closePopup()">{{ 'POPUP_ORGANIZATION.CLOSE_BUTTON' | translate }}</button>
</div>
