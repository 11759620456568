import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ErrorsService } from '@app/modules/audit-event/services/errors.service';
import { Error } from '@app/modules/audit-event/models/error.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mcon-popup-error-code-leyend',
  templateUrl: './popup-error-code-leyend.component.html',
  styleUrls: ['./popup-error-code-leyend.component.scss'],
})
export class PopupErrorCodeLeyendComponent implements OnInit {
  errors: Error[] = [];
  isLoading: boolean = true;
  subscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<PopupErrorCodeLeyendComponent>,
    private errorsService: ErrorsService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.errorsService.getErrors().subscribe({
      next: (data) => {
        this.errors = data;
      },
      error: (error) => {
        console.error('Error fetching data', error);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  closePopup(): void {
    this.subscription.unsubscribe();
    this.dialogRef.close();
  }
}
