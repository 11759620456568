<mat-card>
  <mat-card-title style="margin-bottom: 16px;">
    {{'POPUP_REFRESH_CREDENTIALS.TITLE' | translate}}
  </mat-card-title>
  <mat-card-content class="col-12 mt-2">
    {{'POPUP_REFRESH_CREDENTIALS.CONTENT' | translate}}
  </mat-card-content>
</mat-card>
<div class="button-container">
  <button mat-button mat-dialog-close>{{'POPUP_REFRESH_CREDENTIALS.CLOSE_BUTTON' | translate}}</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{'POPUP_REFRESH_CREDENTIALS.ACCEPT_BUTTON' | translate}}</button>
</div>
