import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExpirationGuard } from './modules/authentication/guards/expiration.guard';
import { UserGuard } from './modules/authentication/guards/user.guard';

const routes: Routes = [
    {
      path: 'dashboard',
      loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      canActivate: [UserGuard, ExpirationGuard],
    },
    {
      path: 'organizations',
      loadChildren: () => import('./modules/organization/organization.module').then(m => m.OrganizationModule),
      canActivate: [UserGuard, ExpirationGuard],
    },
    {
      path: 'organizations/:id',
      loadChildren: () => import('./modules/organization/organization.module').then(m => m.OrganizationModule),
      canActivate: [UserGuard, ExpirationGuard],
    },
    {
      path: 'activations',
      loadChildren: () => import('./modules/service-request/service-request.module').then(m => m.ServiceRequestModule),
      canActivate: [UserGuard, ExpirationGuard],
    },
    {
      path: 'device',
      loadChildren: () => import('./modules/device/device.module').then(m => m.DeviceModule),
      canActivate: [UserGuard, ExpirationGuard],
    },
    {
      path: 'audit-event',
      loadChildren: () => import('./modules/audit-event/audit-event.module').then(m => m.AuditEventModule),
      canActivate: [UserGuard, ExpirationGuard],
    },
    {
      path: 'service-request',
      loadChildren: () => import('./modules/service-request/service-request.module').then(m => m.ServiceRequestModule),
      canActivate: [UserGuard, ExpirationGuard],
    },
    {
      path: 'service-request/:id',
      loadChildren: () => import('./modules/service-request/service-request.module').then(m => m.ServiceRequestModule),
      canActivate: [UserGuard, ExpirationGuard],
    },
    {
      path: 'service-request/organization/:cif',
      loadChildren: () => import('./modules/service-request/service-request.module').then(m => m.ServiceRequestModule),
      canActivate: [UserGuard, ExpirationGuard],
    },
    {
      path: 'observation',
      loadChildren: () => import('./modules/observation/observation.module').then(m => m.ObservationModule),
      canActivate: [UserGuard, ExpirationGuard],
    },
    {
      path: 'query',
      loadChildren: () => import('./modules/query/query.module').then(m => m.QueryModule),
      canActivate: [UserGuard, ExpirationGuard],
    },
    {
      path: 'endpoint/:id',
      loadChildren: () => import('./modules/endpoint/endpoint.module').then(m => m.EndpointModule),
      canActivate: [UserGuard, ExpirationGuard],
    },
    {
      path: 'login',
      loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    { path: '', pathMatch: 'full', redirectTo: 'dashboard'},
    { path: '**', pathMatch: 'full', redirectTo: 'dashboard'},
    // {
    //   path: 'healthcareService',
    //   loadChildren: () => import('./modules/healthcare-service/healthcare-service.module').then(m => m.HealthcareServiceModule)
    // },
    // {
    //   path: 'contract',
    //   loadChildren: () => import('./modules/contract/contract.module').then(m => m.ContractModule)
    // },
    // {
    //   path: 'condition',
    //   loadChildren: () => import('./modules/condition/condition.module').then(m => m.ConditionModule)
    // },
    // {
    //   path: 'clausula',
    //   loadChildren: () => import('./modules/activity-definition/activity-definition.module').then(m => m.ActivityDefinitionModule)
    // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
