<div id="footer-container">
    <div class="container-fluid no-print">
        <div class="row footer-content-container" style="width: 100%">
          <div class="logo-container">
            <img alt="Salut" class="logo-salut" src="../../../../assets/images/logo-salut.svg" width="75px" height="22px">
            <img alt="Generalitat" class="logo-generalitat" src="../../../../assets/images/logo-generalitat.svg" width="97px" height="25px">
          </div>
        </div>
    </div>
  </div>

