<mat-card *ngIf="data">
    <div class="content flex">
        <mat-card-content class="column col-12 mt-2 secret-key-card">
            <b>{{'NEW_ORGANIZATION.CREATE_SUCCESS_KEY' | translate }}</b>
            <div>
              <p>{{data}}</p>
              <mat-icon class="cursor-pointer" (click)="copyToClipboard(data)">content_copy</mat-icon>
            </div>
        </mat-card-content>
    </div>
    <div class="button-container">
        <button mat-button class="btn cancel-button" (click)="closePopup()">{{'POPUP_ORGANIZATION.CLOSE_BUTTON' | translate}}</button>
    </div>
</mat-card>
