<mat-label>{{'NEW_ACTIVITY_DEFINITION.DEVICE_TYPE' | translate }}</mat-label>
  <mat-form-field appearance="fill" dense>
    <mat-label>{{'NEW_ACTIVITY_DEFINITION.DEVICE_TYPE_LABEL' | translate }}</mat-label>
    <mat-select
    [formControl]="items"
      multiple
      #opts
      (openedChange)="searchCtrl.setValue('')"
    >
    <div class="checkbox-container">
        <mat-checkbox
        color="primary"
        [checked]="selected(opts) === 'all'"
        [indeterminate]="selected(opts) === 'some'"
        (change)="toggleSelection(opts)"
        [matTooltip]="
        selected(opts) === 'all' ? 'Deseleccionar tot' : 'Seleccionar tot'
          "
          matTooltipPosition="above"
          >
        </mat-checkbox>
        
        <mat-form-field class="search-input" appearance="outline">
          <input
          matInput
            #input
            placeholder="{{'NEW_ACTIVITY_DEFINITION.DEVICE_TYPE_SEARCH' | translate }}"
            [formControl]="searchCtrl"
            (keydown)="onKeydown($event, input)"
            />
          </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        
        <div class="options">
          <div *ngIf="searchCtrl.value && !opts.options.length" class="no-data">
            {{'NEW_ACTIVITY_DEFINITION.DEVICE_TYPE_NO_RESULTS' | translate }} "{{ searchCtrl.value }}"
        </div>
        
        <mat-option
        *ngFor="let item of filteredItems | async"
        [value]="item.id"
        (onSelectionChange)="onSelectionChange($event)"
        >{{ item.name }}</mat-option
        >
      </div>
    </mat-select>
  </mat-form-field>