import { Component, Inject, AfterViewInit , ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Endpoint, EndpointResource } from '@app/modules/endpoint/models/endpoint.model';
import { Contact, ORGResource, OrganizationTableList } from '@app/modules/organization/models/organization.model';
import { EndpointService } from '@app/modules/endpoint/services/endpoint.service';
import { OrganizationService } from '@app/modules/organization/services/organization.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { firstValueFrom } from 'rxjs';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';

@Component({
  selector: 'mcon-popup-organization',
  templateUrl: './popup-organization.component.html',
  styleUrls: ['./popup-organization.component.scss']
})
export class PopupOrganizationComponent implements AfterViewInit {

  organization: ORGResource;
  endpoint: EndpointResource;
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<PopupOrganizationComponent>,
    private _organizationService: OrganizationService,
    private _endpointService: EndpointService,
    @Inject(MAT_DIALOG_DATA) public data: OrganizationTableList,
    private _snackBar: MatSnackBar,
    private clipboard: Clipboard,
    private cdr: ChangeDetectorRef
  ) { }

  async ngAfterViewInit(): Promise<void> {
    if (this.data.id) {
      await this.getOrganizationById(this.data.id);
    }
  }

  async getOrganizationById(organizationId: string): Promise<void> {
    try {
      const response: ORGResource = await firstValueFrom(this._organizationService.getOrganizationById(organizationId));
      this.organization = response;

      if (this.data.id && this.organization.type[0].coding[0].code === 'prov') {
        await this.getEndpointByOrganizationId(this.data.id);
      }

      this.isLoading = true;
      this.cdr.detectChanges();
    } catch (error) {
      console.warn(error);
    }
  }

  async getEndpointByOrganizationId(organizationId: string): Promise<void> {
    try {
      const response: Endpoint = await firstValueFrom(this._endpointService.getEndpointByOrganizationId(organizationId));
      this.endpoint = response.entry[0].resource;
    } catch (error) {
      console.warn(error);
    }
  }

  copyToClipboard(value: string) {
    this.clipboard.copy(value);
    this._snackBar.openFromComponent(SnackBarComponent, {
      data: 'SNACK_BAR.COPIED',
      panelClass: ["snack-success"]
    });
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  contactsToString(contacts: Contact[]){
    return this._organizationService.contactsToString(contacts);
  }
}
