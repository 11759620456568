import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChartData } from '../../models/chart-data.model';

@Component({
  selector: 'horizontal-bar-chart',
  templateUrl: './horizontal-bar-chart.component.html',
  styleUrls: ['./horizontal-bar-chart.component.scss']
})
export class HorizontalBarChartComponent {

  @Input() view: [number, number];
  @Input() chartData: ChartData[];
  @Input() scheme: string = `ocean`;
  @Input() showXAxis: boolean = true;
  @Input() showYAxis: boolean = true;
  @Input() gradient: boolean = false;
  @Input() showLegend: boolean = false;
  @Input() legendTitle: string = `legendTitle`;
  @Input() showXAxisLabel: boolean = true;
  @Input() showYAxisLabel: boolean = true;
  @Input() xAxisLabel: string = 'xAxisLabel';
  @Input() yAxisLabel: string = `yAxisLabel`;
  @Output() select: EventEmitter<ChartData> = new EventEmitter<ChartData>();

  constructor(
  ) {}

  onSelect(event: ChartData){
    this.select.emit(event);
  }

}
