import { Component, OnInit } from '@angular/core';
import { Patient } from '../../models/patient.model';
import { Practitioner } from '../../models/practitioner.model';
import { PatientService } from '../../services/patient.service';
import { PractitionerService } from '../../services/practitioner.service';
import { tap } from 'rxjs';

@Component({
  selector: 'app-info-card-user',
  templateUrl: './info-card-user.component.html',
  styleUrls: ['./info-card-user.component.scss']
})
export class InfoCardUserComponent implements OnInit {

  patientName: string;
  patientId: string;
  practitionerName: string;
  practitionerId: string;
  iconCards: { icon: string }[] = [{ icon: 'account_circle' }, { icon: 'health_and_safety' }];

  constructor(
    private _practitionerService: PractitionerService,
    private _patientService: PatientService
  ) { }

  ngOnInit(): void {
    this.getPatientAndPractitioner();
  }

  getPatientAndPractitioner() {
    this._patientService.getPatient()
    .pipe(
      tap({
        next: (x: Patient) => {
          this.patientName = x.name[0].given[0] + " " + x.name[0].family;
          this.patientId = x.id;
          this.getPractitioner();
        }
      }),
    )
    .subscribe()
  }

  getPractitioner() {
    this._practitionerService.getPractitioner()
    .pipe(
      tap({
        next: (x: Practitioner) => {
          this.practitionerName = x.name[0].given[0] + ' ' + x.name[0].family;
          this.practitionerId = x.id;
        }
      }),
    )
    .subscribe()
  }
}
