<mat-card>

  <div class="advanced-pie-chart-container">

    <ng-content></ng-content>

    <ngx-charts-advanced-pie-chart
      [view]="view"
      scheme="ocean"
      [results]="chartData"
      [gradient]="gradient">
    </ngx-charts-advanced-pie-chart>
  </div>

</mat-card>
