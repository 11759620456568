import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html'
})
export class LanguageSelectorComponent {

  constructor(private _translate: TranslateService) {
    _translate.addLangs(['cat']);
    _translate.setDefaultLang('cat');
  }

  switchLanguage(lang: string) {
    this._translate.use(lang)
  }

}
