<div class="container" *ngIf="patientName && patientId && practitionerName">
    <div class="row row-cards-info">
        <div class="col-5 row card-info">
            <div class="col-3 col-card-icon">
                <span class="mc-icon-secondary material-symbols-outlined">{{ iconCards[0].icon }}</span>
            </div>
            <div class="col-9 col-card-user">
                <ol class="list-card-user">
                    <li *ngIf="patientName" class="fw-500">{{ patientName }}</li>
                    <!-- <li *ngIf="patientId">{{ patientId }}</li> -->
                </ol>
            </div>
        </div>
        <!-- <div class="col-4 row card-info">
            <div class="col-4 col-card-icon">
                <mat-icon class="mc-icon-secondary">{{ iconCards[1].icon }}</mat-icon>
            </div>
            <div class="col-8 col-card-user">
                <ol class="list-card-user">
                    <li>{{ organization?.name }}</li>
                    <span class="list-card-user" *ngFor="let tel of telecom; let i = index">
                        <li *ngIf="organization.name">{{ tel.value }}</li>
                    </span>
                </ol>
            </div>
        </div> -->
        <div class="col-5 row card-info">
            <div class="col-3 col-card-icon">
                <span class="mc-icon-secondary material-symbols-outlined">{{ iconCards[1].icon }}</span>
            </div>
            <div class="col-9 col-card-user" *ngIf="practitionerName">
                <ol class="list-card-user">
                    <li class="fw-500">{{ practitionerName }}</li>
                </ol>
            </div>
        </div>
    </div>
</div>
