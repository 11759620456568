<mat-label>{{type === conditionType ? ('SELECT_SEARCH.CONDITION_TITLE' | translate) : ('SELECT_SEARCH.ENTITAT_TITLE' | translate)}}</mat-label>
  <mat-form-field appearance="fill" dense>
    <mat-label>{{type === conditionType ? ('SELECT_SEARCH.SELECT_CONDITION' | translate) : ('SELECT_SEARCH.SELECT_ENTITAT' | translate)}}</mat-label>
    <mat-select
    [formControl]="items"
      multiple
      #opts
      (openedChange)="searchCtrl.setValue('')"
    >
    <div class="checkbox-container">
        <mat-form-field class="search-input" appearance="outline">
          <input
          matInput
            #input
            placeholder="{{type === conditionType ? ('SELECT_SEARCH.SEARCH_CONDITION' | translate) : ('SELECT_SEARCH.SEARCH_ENTITAT' | translate)}}"
            [formControl]="searchCtrl"
            (keydown)="onKeydown($event, input)"
            />
          </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        
        <div class="options">
          <div *ngIf="searchCtrl.value && !opts.options.length" class="no-data">
            {{'NEW_ACTIVITY_DEFINITION.DEVICE_TYPE_NO_RESULTS' | translate }} "{{ searchCtrl.value }}"
        </div>
        
        <mat-option
        *ngFor="let item of filteredItems | async"
        [value]="item.id"
        (onSelectionChange)="onSelectionChange($event)"
        >{{ item.name }}</mat-option
        >
      </div>
    </mat-select>
  </mat-form-field>