import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@authentication/services/authentication.service';
import {Router} from "@angular/router"
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  constructor(private _authenticationService: AuthenticationService,
              private router: Router) { }

  ngOnInit(): void {
  }

  downloadPDF() {
    window.open(`/assets/pdf/Manual d' usuari.pdf`, '_blank');
  }

  logOut() {
    this._authenticationService.deleteAccessToken();
    this.router.navigate(['/login/error']);
  }

  openMyMenu(menuTrigger: MatMenuTrigger) {
    menuTrigger.openMenu();
}

closeMyMenu(menuTrigger: MatMenuTrigger) {
  menuTrigger.closeMenu();
}

}

