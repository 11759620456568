<mat-card *ngIf="data">
  <mat-card-title style="margin-bottom: 16px;">{{ 'SERVICE_REQUEST.POPUP.HEADER' | translate }}</mat-card-title>
  <mat-card-content class="column col-12 mt-2">

    <mat-tab-group>
      <mat-tab label="Sol·licitud de servei">
        <div class="tab-content">
          <div class="id-container">
            <p><b>{{ 'SERVICE_REQUEST.POPUP.ID' | translate }}</b> {{ data.id.value ? data.id.value : '-' }}</p>
            <mat-icon class="copy-icon" (click)="copyToClipboard(data.id.value)" *ngIf="data.id.value">content_copy</mat-icon>
          </div>

          <div class="id-container">
            <p><b>{{ 'SERVICE_REQUEST.POPUP.AZURE_ID' | translate }}</b> {{ data.azureId.value ? data.azureId.value : '-' }}</p>
            <mat-icon class="copy-icon" (click)="copyToClipboard(data.azureId.value)" *ngIf="data.azureId.value">content_copy</mat-icon>
          </div>

          <p><b>{{ 'SERVICE_REQUEST.POPUP.PHASE' | translate }}</b> {{ data.phase.value ? data.phase.value : '-' }}</p>
          <p><b>{{ 'SERVICE_REQUEST.POPUP.STATUS' | translate }}</b>
            <ng-container [ngSwitch]="data.status.value">
              <ng-container *ngSwitchCase="'all'">Tots</ng-container>
              <ng-container *ngSwitchCase="'completed'">Completat</ng-container>
              <ng-container *ngSwitchCase="'active'">Actiu</ng-container>
              <ng-container *ngSwitchCase="'on-hold'">En espera</ng-container>
              <ng-container *ngSwitchCase="'revoked'">Revocat</ng-container>
              <ng-container *ngSwitchCase="'draft'">Esborrany</ng-container>
              <ng-container *ngSwitchCase="'unknown'">Desconegut</ng-container>
              <ng-container *ngSwitchCase="'entered-in-error'">Introduït per error</ng-container>
              <ng-container *ngSwitchDefault>-</ng-container>
            </ng-container>
          </p>

          <p *ngIf="data.status.value === 'revoked'"><b>{{ data.reasonForCancellation.label }}:</b> {{ data.reasonForCancellation.value ? (data.reasonForCancellation.value ) : '-' }}</p>
          <p *ngIf="data.status.value === 'unknown' || data.status.value === 'entered-in-error'"><b>{{ data.numberOfRetries.label }}:</b> {{ data.numberOfRetries.value ? (data.numberOfRetries.value ) : '-' }}</p>

          <p><b>{{ 'SERVICE_REQUEST.POPUP.AUTHORED_ON' | translate }}</b> {{ data.authoredOn.value ? (data.authoredOn.value | date: "dd/MM/yyyy" : "+0000") : '-' }}</p>

          <p><b>{{ 'SERVICE_REQUEST.POPUP.ADJUDICATARI_CIF' | translate }}</b> {{ data.petitionerAlias.value ? data.adjudicatariCif.value : '-' }}</p>
          <p><b>{{ 'SERVICE_REQUEST.POPUP.ADJUDICATARI_NAME' | translate }}</b> {{ data.adjudicatariName.value ? data.adjudicatariName.value : '-' }}</p>

          <p><b>{{ 'SERVICE_REQUEST.POPUP.PETICIONARI_CIF' | translate }}</b> {{ data.petitionerCif.value ? data.petitionerCif.value : '-' }}</p>
          <p><b>{{ 'SERVICE_REQUEST.POPUP.PETICIONARI_NAME' | translate }}</b> {{ data.petitionerName.value ? data.petitionerName.value : '-' }}</p>

          <p><b>{{ 'SERVICE_REQUEST.POPUP.DISPOSITIUS_LOT_CONTRACTUAL' | translate }}</b> {{ data.dispositiusLotContractual.value ? data.dispositiusLotContractual.value : '-' }}</p>

          <p><b>{{ 'SERVICE_REQUEST.POPUP.COLLEGIATE_NAME' | translate }}</b> {{ data.practitionerName.value && data.practitionerName.value!='' ? data.practitionerName.value : '-' }}</p>
          <p><b>{{ 'SERVICE_REQUEST.POPUP.COLLEGIATE_NUMBER' | translate }}</b> {{ data.practitionerNumber.value && data.practitionerNumber.value!='' ? data.practitionerNumber.value : '-' }}</p>

          <p><b>{{ 'SERVICE_REQUEST.POPUP.UP_NAME' | translate }}</b> {{ data.upName.value ? data.upName.value : '-' }}</p>
          <p><b>{{ 'SERVICE_REQUEST.POPUP.UP_CODE' | translate }}</b> {{ data.upCode.value ? data.upCode.value : '-' }}</p>
          <p><b>{{ 'SERVICE_REQUEST.POPUP.AS_NEEDED_BOOLEAN' | translate }}</b>{{ data.homeDelivery.value ? data.homeDelivery.value : '-' }}</p>
          <p><b>{{ 'SERVICE_REQUEST.POPUP.START_DATE' | translate }}</b> {{ data.startDate.value ? (data.startDate.value | date: "dd/MM/yyyy" : "+0000") : '-' }}</p>
          <p><b>{{ 'SERVICE_REQUEST.POPUP.END_DATE' | translate }}</b> {{ data.endDate.value ? (data.endDate.value | date: "dd/MM/yyyy" : "+0000") : '-' }}</p>
          <p *ngIf="data.migrated && data.migrated.value == 'Sí'"><b>{{ data.migrated.label }}</b></p>
          <div *ngIf="data.notes && data.notes.value.length">
            <div *ngFor="let note of data.notes.value; let i = index">
              <p>
                <b>
                  {{ i === 0 ? 'Nota: ' : 'Nota ' + (i + 1) + ': ' }}
                </b>
                {{note.text ? note.text : '-'}}
              </p>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Pacient">
        <div class="tab-content">
          <div class="id-container">
            <p><b>{{ 'SERVICE_REQUEST.POPUP.PATIENT_ID' | translate }}</b> {{ data.id.value ? data.patientID.value : '-' }}</p>
            <mat-icon class="copy-icon" (click)="copyToClipboard(data.patientID.value!)" *ngIf="data.patientID.value">content_copy</mat-icon>
          </div>
          <p><b>{{ 'SERVICE_REQUEST.POPUP.PATIENT_IDENTIFICATION' | translate }}</b> {{ data.patientIdentification.value ? data.patientIdentification.value : '-' }}</p>
          <p><b>{{ 'SERVICE_REQUEST.POPUP.PATIENT_NAME' | translate }}</b> {{ data.patientName.value && data.patientName.value != ' ' ? data.patientName.value : '-' }}</p>
          <p><b>{{ 'SERVICE_REQUEST.POPUP.PATIENT_ADDRESS' | translate }}</b> {{ data.patientAddress.value && data.patientAddress.value.trim() != '' ? data.patientAddress.value : '-' }}</p>
          <p><b>{{ 'SERVICE_REQUEST.POPUP.PATIENT_TELECOM' | translate }}</b> {{ data.patientTelecom.value && data.patientTelecom.value != ' ' ? data.patientTelecom.value : '-' }}</p>
          <p><b>{{ 'SERVICE_REQUEST.POPUP.PATIENT_EMAIL' | translate }}</b> {{ data.patientEmail.value && data.patientEmail.value != ' ' ? data.patientEmail.value : '-' }}</p>
          <p><b>{{ 'SERVICE_REQUEST.POPUP.CONTACT_FULL_NAME' | translate }}</b> {{ data.contactFullName.value && data.contactFullName.value != ' ' ? data.contactFullName.value : '-' }}</p>
          <p><b>{{ 'SERVICE_REQUEST.POPUP.CONTACT_TELECOM' | translate }}</b> {{ data.contactTelecom.value ? data.contactTelecom.value : '-' }}</p>
          <p><b>{{ 'SERVICE_REQUEST.POPUP.CONTACT_ADDRESS' | translate }}</b> {{ data.contactAddress.value && data.contactAddress.value != ' ' ? data.contactAddress.value : '-' }}</p>
        </div>
      </mat-tab>

    </mat-tab-group>

  </mat-card-content>
</mat-card>
<div class="button-container">
  <button mat-button class="btn cancel-button" (click)="closePopup()">{{ 'SERVICE_REQUEST.POPUP.CLOSE_BUTTON' | translate }}</button>
</div>
