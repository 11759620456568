import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { map, Observable } from 'rxjs';
import { RequestOrganization } from '../models/organization-request.model';
import { Contact, Identifier, Organization, OrganizationTable, OrganizationTableList, ORGResource } from '../models/organization.model';
import { SecretKey } from '../models/secret-key.model';
import { Endpoint } from '@app/modules/endpoint/models/endpoint.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  baseUrl = environment.urlBffApi;
  identifier_organization_cif = 'urn:oid:1.3.6.1.4.1.19126.21';
  identifier_organization_phase = 'urn:m-connecta:organitzacio-estat';


  constructor(private _http: HttpClient) { }

  getOrganizationsTable(organizationTypeSelected?: string): Observable<OrganizationTable> {
    let url = this.baseUrl + 'Organization?_count=15&_total=accurate';
    if (organizationTypeSelected === "prov") url += '&type=prov';
    if (organizationTypeSelected === "dept") url += '&type=dept';
    if (organizationTypeSelected === "team") url += '&type=team';
    if (organizationTypeSelected === "govt") url += '&type=govt';
    return this._http.get<Organization>(url).pipe(
      map(res => {
        return this.extractOrganizationProperties(res);
      })
    );
  }

  getOrganizationTableById(organizationtId?: string): Observable<OrganizationTable> {
    let url = this.baseUrl + 'Organization?_total=accurate&_id='+ organizationtId;
    return this._http.get<Organization>(url).pipe(
      map(res => {
        return this.extractOrganizationProperties(res);
      })
    );
  }

  getActiveProvOrganizationsWithNoActiveSRs(startDate: string, endDate:string): Observable<OrganizationTable>{
    const url = `${this.baseUrl}api/metrics/ProvOrganizations?startDate=${startDate}&endDate=${endDate}`;
    return this._http.get<Organization>(url).pipe(
      map(res => {
        return this.extractOrganizationProperties(res);
      })
    );
  }

  getActiveGovtOrganizationsWithNoPrescriptionRequests(startDate: string, endDate:string): Observable<OrganizationTable>{
    const url = `${this.baseUrl}api/metrics/GovtOrganizations?startDate=${startDate}&endDate=${endDate}`;
    return this._http.get<Organization>(url).pipe(
      map(res => {
        return this.extractOrganizationProperties(res);
      })
    );
  }

  getNextOrganization(organizationUrl: string): Observable<OrganizationTable> {
    return this._http.get<Organization>(organizationUrl).pipe(
      map(res => {
        return this.extractOrganizationProperties(res);
      })
    );
  }

  getOrganizationById(organizationId: string): Observable<ORGResource> {
    return this._http.get<ORGResource>(this.baseUrl + 'Organization/'
      + organizationId);
  }

  getOrganizationByCif(organizationCif: string): Observable<Organization> {
    return this._http.get<Organization>(this.baseUrl + 'Organization?identifier='
      + organizationCif);
  }

  createOrganization(request: RequestOrganization): Observable<Endpoint> {
    return this._http.post<Endpoint>(this.baseUrl + 'Organization', request);
  }

  editOrganization(request: RequestOrganization, organizationId: string) {
    return this._http.put<Endpoint>(this.baseUrl + 'Organization/' + organizationId, request);
  }

  activateOrganization(organizationId: string) {
    return this._http.patch<Endpoint>(this.baseUrl + 'Organization/' + organizationId,null);
  }

  deleteOrganization(organizationId: string) {
    return this._http.delete(this.baseUrl + 'Organization/' + organizationId);
  }

  securePasswordOrganization(organizationId: string): Observable<SecretKey> {
    const body = {
      "id": organizationId
    };
    return this._http.put<SecretKey>(this.baseUrl + 'Organization/securePassword', body);
  }

  extractOrganizationProperties(organizationsList: Organization): OrganizationTable {
    const organizationTable: OrganizationTable = { organizationTableList: [], link: []};

    if (organizationsList.total) organizationTable.total = organizationsList.total;

    for (const obj of organizationsList.entry) {
      const newObj: OrganizationTableList = {};
      const resource = obj.resource;
      if (resource.name) newObj.name = resource.name;
      if (resource.identifier) {
        const cif = this.filterIdentifierBySystem(resource, this.identifier_organization_cif);
        if(cif[0]) newObj.cif = cif[0].value;

        const phase = this.filterIdentifierBySystem(resource, this.identifier_organization_phase);
        if(phase[0]) newObj.phase = phase[0].value;
      }
      if (resource.active) newObj.active = resource.active;
      if (resource.id) newObj.id = resource.id;
      if (resource.contact && resource.contact.length > 0) {
        newObj.contact = resource.contact;
      }
      if (resource.telecom) newObj.telecom = resource.telecom;
      if (resource.meta && resource.meta.lastUpdated) newObj.lastUpdated = resource.meta.lastUpdated;
      if (resource.address && resource.address.length > 0) newObj.address = resource.address;
      if (resource.alias && resource.alias.length > 0) newObj.alias = resource.alias;
      if (resource.type && resource.type.length > 0 && resource.type[0].coding && resource.type[0].coding.length > 0) newObj.code = resource.type[0].coding[0].code;

      organizationTable.organizationTableList.push(newObj);
    }
    organizationTable.link = organizationsList.link;
    return organizationTable;
  }

  filterIdentifierBySystem(resource: ORGResource, system: string): Identifier[] {
    return resource.identifier!.filter( (el) => {
      return el.system === system;
    });
  }

  contactsToString(contacts: Contact[]){
    if (!contacts) return "";

    let contactsString = '';
    for (const contact of contacts) {
        if (contact.name.given[0]) contactsString += contact.name.given[0];
        if (contact.name.family) contactsString += ' ' + contact.name.family;
        contactsString += ', '
    }

    contactsString = contactsString.slice(0, -2);

    return contactsString
  }
}
