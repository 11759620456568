import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-popup-confirm-action',
  templateUrl: './popup-confirm-action.component.html',
  styleUrls: ['./popup-confirm-action.component.scss']
})
export class PopupConfirmActionComponent{

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}



}
