<mat-card>
  <mat-card-title style="margin-bottom: 16px;">
    {{'POPUP_CONFIRM_ACTION.TITLE' | translate}}
  </mat-card-title>
  <mat-card-content class="col-12 mt-2">
    {{'POPUP_CONFIRM_ACTION.CONTENT' | translate}}
  </mat-card-content>
</mat-card>
<div class="button-container">
  <button mat-button mat-dialog-close>{{'POPUP_CONFIRM_ACTION.CLOSE_BUTTON' | translate}}</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{'POPUP_CONFIRM_ACTION.ACCEPT_BUTTON' | translate}}</button>
</div>
