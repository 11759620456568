import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-popup-information',
  templateUrl: './popup-information.component.html',
  styleUrls: ['./popup-information.component.scss']
})
export class PopupInformationComponent{
  constructor(
    public dialogRef: MatDialogRef<PopupInformationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    closePopup(): void {
      this.dialogRef.close();
    }
}
