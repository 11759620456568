import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';

@Component({
  selector: 'app-popup-secret-key',
  templateUrl: './popup-secret-key.component.html',
  styleUrls: ['./popup-secret-key.component.scss']
})
export class PopupSecretKeyComponent{
  constructor(
    public dialogRef: MatDialogRef<PopupSecretKeyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar
    ) { }
    
    closePopup(): void {
      this.dialogRef.close();
    }

    copyToClipboard(text: string) {
      navigator.clipboard.writeText(text);
      this._snackBar.openFromComponent(SnackBarComponent, {
        data: 'SNACK_BAR.CPOY_TEXT_SUCCES',
        panelClass: ["snack-success"]
      });
    }
    

}
