import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Patient, PatientFromAPI } from '@shared/models/patient.model';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  get accessToken(): string{
    return localStorage.getItem('accessToken')!;
  }

  patientSubject: ReplaySubject<Patient> = new ReplaySubject<Patient>(1);
  baseUrl: string = environment.urlBffApi;

  constructor(private _http: HttpClient) { }

  getPatient(): Observable<Patient>{
    return this.patientSubject.asObservable();
  }

  getPatientById(id: string): Observable<PatientFromAPI> {
    return this._http.get<PatientFromAPI>(this.baseUrl + 'Patient/' + id);
  }

  getPatientByIdentifier(identifier: string): Observable<PatientFromAPI> {
    return this._http.get<PatientFromAPI>(this.baseUrl + 'Patient?identifier=' + identifier);
  }

  setData(patientId: string): void {
    this._http.get<Patient>(`${this.baseUrl}` + "Patient/" + patientId).subscribe((res: Patient) => {
      this.patientSubject.next(res);
    });
  }
}
