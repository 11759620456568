import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyOption as MatOption, MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { Observable, startWith, map } from 'rxjs';

interface Item {
  name: string;
  id: string;
}

@Component({
  selector: 'app-multi-select-search',
  templateUrl: './multi-select-search.component.html',
  styleUrls: ['./multi-select-search.component.scss']
})
export class MultiSelectSearchComponent implements OnInit {
  items = new UntypedFormControl([]);
  filteredItems!: Observable<Item[]>;
  searchCtrl = new UntypedFormControl('');
  @Input() itemsList: { name: string, id: string }[];
  @Output() itemsSelected: EventEmitter<string[]> = new EventEmitter<string[]>();

  ngOnInit(): void {
    this.filteredItems = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '', this.itemsList))
    );
  }

  private _filter(value: string, options: Item[]): Item[] {
    const filterValue = value.toLowerCase();
    return options.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  selected(e: MatSelect): 'some' | 'all' | undefined {
    if (e.options == null || e.options.length === 0) {
      return undefined;
    } else if (e._selectionModel.selected.length === e.options.length) {
      return 'all';
    } else if (
      e._selectionModel.selected.length > 0 &&
      e._selectionModel.selected.length < e.options.length
    ) {
      return 'some';
    } else {
      return undefined;
    }
  }

  toggleSelection(e: MatSelect): void {
    let values: Item[] = this.items.value || ([] as Item[]);
    e.options.forEach((item: MatOption) => {
      if (this.selected(e) !== 'all' && !values.includes(item.value)) {
        values.push(item.value);
      } else if (this.selected(e) === 'all' && values?.includes(item.value)) {
        values = values.filter((value) => value !== item.value);
      }
    });
    this.items?.setValue(values);
    this.itemsSelected.emit(this.items.value);
  }

  onSelectionChange(a: MatOptionSelectionChange): void {
    let values: Item[] = this.items?.value || ([] as Item[]);
    if (a.isUserInput) {
      if (a.source.selected && !values.includes(a.source.value)) {
        values.push(a.source.value);
      } else if (!a.source.selected && values.includes(a.source.value)) {
        values = values.filter((value) => value !== a.source.value);
      }
      this.items?.setValue(values);
      this.itemsSelected.emit(this.items.value);
    }
  }

  onKeydown(e: KeyboardEvent, i: HTMLInputElement) {
    i.onkeydown?.(e);
    e.stopPropagation();
  }
}
