import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-location-back',
  templateUrl: './location-back.component.html',
  styleUrls: ['./location-back.component.scss']
})
export class LocationBackComponent implements OnInit {

  @Input() isEnabled: boolean = true;

  constructor(private location: Location) { }

  disable(){
    this.isEnabled = false;
  }

  enable(){
    this.isEnabled = true;
  }

  ngOnInit(): void {
  }

  back(){
    if (this.isEnabled) {
      this.location.back();
    }
  }
}
