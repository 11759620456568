import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { EndpointRequest } from '../models/endpoint-request.model';
import { Endpoint } from '../models/endpoint.model';

@Injectable({
  providedIn: 'root'
})
export class EndpointService {

  baseUrl = environment.urlBffApi;

  constructor(private _http: HttpClient) { }

  getEndpointByOrganizationId(organizationId: String): Observable<Endpoint> {
    return this._http.get<Endpoint>(this.baseUrl + 'Endpoint?organization=' + organizationId);
  }

  createEndpoint(endpointRequest: EndpointRequest): Observable<Endpoint> {
    return this._http.post<Endpoint>(this.baseUrl + 'Endpoint', endpointRequest);
  }

  editEndpoint(endpointId: string, endpointRequest: EndpointRequest): Observable<Endpoint> {
    return this._http.put<Endpoint>(this.baseUrl + 'Endpoint/' + endpointId, endpointRequest);
  }
}
