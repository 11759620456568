import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Events } from '@app/modules/audit-event/models/audit-event.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';

@Component({
  selector: 'mcon-popup-audit-event',
  templateUrl: './popup-audit-event.component.html',
  styleUrls: ['./popup-audit-event.component.scss']
})
export class PopupAuditEventComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Events,
    public dialogRef: MatDialogRef<PopupAuditEventComponent>,
    private router: Router,
    private _snackBar: MatSnackBar,
    private clipboard: Clipboard
  ) { }

  viewServiceRequest(serviceRequestId: string){
    this.router.navigate(['service-request', serviceRequestId]);
    this.closePopup();
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  copyToClipboard(value: string) {
    this.clipboard.copy(value);
    this._snackBar.openFromComponent(SnackBarComponent, {
      data: 'SNACK_BAR.COPIED',
      panelClass: ["snack-success"]
    });
  }

  checkServiceRequestHisIdValue(data: Events){
    // Siempre que sea activació o lliurament y el Id de sol·licitud (HIS) contenga el texto “No s'ha trobat la informació”, cambiarlo por "no s'està mostrant tota la informació de la BBDD”
    if (data.phase?.value == 'LLiurament' || data.phase?.value == 'Activació' && data.serviceRequestHisId?.value == "No s'ha trobat la informació") {
      return "No s'està mostrant tota la informació de la BBDD"
    }

    if (data.serviceRequestHisId && data.serviceRequestHisId?.value) {
      return data.serviceRequestHisId.value;
    }

    return "-"
  }

}
