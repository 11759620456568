<form>
  <mat-form-field appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <input type="text"
           placeholder="Selecciona un"
           matInput
           [formControl]="myControl"
           [matAutocomplete]="auto"
           [value]="myControl.value">
    <mat-autocomplete autoSelectActiveOption #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.display">
        {{ option.display }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
