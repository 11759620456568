<div *ngIf="isLoading">
  <mat-card *ngIf="data">
    <div class="column col-12 card-header-container">
        <div>
            <mat-card-title>{{data.name ? data.name : '-'}}</mat-card-title>
        </div>
        <div>
            <span *ngIf="data.active" matTooltip="{{ 'POPUP_ORGANIZATION.ACTIVE' | translate }}" matTooltipPosition="above" class="material-icons active">
                fiber_manual_record
              </span>
              <span *ngIf="!data.active" matTooltip="{{ 'POPUP_ORGANIZATION.INACTIVE' | translate }}" matTooltipPosition="above" class="material-icons completed">
                fiber_manual_record
              </span>
        </div>
    </div>
    <mat-card-content class="column col-12 mt-2">

        <div class="id-container">
          <p>
            <b>{{'POPUP_ORGANIZATION.ID' | translate}} </b> {{data.id ? data.id : '-'}}
          </p>
          <mat-icon class="copy-icon" (click)="copyToClipboard(data.id)" *ngIf="data.id">content_copy</mat-icon>
        </div>

        <p><b>{{'POPUP_ORGANIZATION.LAST_UPDATE' | translate}} </b> {{data.lastUpdated ? (data.lastUpdated | date: "dd/MM/yyyy" : "+0000") : '-'}}</p>
        <div [ngSwitch]="data.code" style="display: flex; align-items: center;">
            <b>{{'POPUP_ORGANIZATION.TYPE' | translate}}</b>
            <p *ngSwitchCase="'prov'">{{'ORGANIZATION.TABLE.TYPE.TYPE_PROV' | translate }}</p>
            <p *ngSwitchCase="'dept'">{{'ORGANIZATION.TABLE.TYPE.TYPE_DEPT' | translate }}</p>
            <p *ngSwitchCase="'team'">{{'ORGANIZATION.TABLE.TYPE.TYPE_TEAM' | translate }}</p>
            <p *ngSwitchCase="'govt'">{{'ORGANIZATION.TABLE.TYPE.TYPE_GOVT' | translate }}</p>
            <p *ngSwitchDefault>-</p>
        </div>
        <p><b>{{'POPUP_ORGANIZATION.CIF' | translate}} </b> {{data.cif ? data.cif : '-'}}</p>
        <p><b>{{'POPUP_ORGANIZATION.ADDRESS' | translate}} </b> {{data.address && data.address[0] && data.address[0].line[0] ? data.address[0].line[0] : '-'}}</p>
        <p><b>{{'POPUP_ORGANIZATION.TELEPHONE' | translate}} </b> {{data.telecom && data.telecom[0] && data.telecom[0].value ? data.telecom[0].value : '-'}}</p>
        <p><b>{{'POPUP_ORGANIZATION.EMAIL' | translate}} </b> {{data.telecom && data.telecom[1] && data.telecom[1].value ? data.telecom[1].value : '-'}}</p>
        <p><b>{{'POPUP_ORGANIZATION.ALIAS' | translate}} </b> {{data.alias && data.alias[0] ? data.alias[0] : '-'}}</p>

        <p><b>{{'POPUP_ORGANIZATION.ENDPOINT_NAME' | translate}} </b> {{endpoint && endpoint.name ? endpoint.name : '-'}}</p>
        <p><b>{{'POPUP_ORGANIZATION.ENDPOINT_ADDRESS' | translate}} </b> {{endpoint && endpoint.address ? endpoint.address : '-'}}</p>
        <p><b>{{'POPUP_ORGANIZATION.CONTACTS' | translate}} </b> {{data.contact ? contactsToString(data.contact) : '-'}}</p>

    </mat-card-content>
  </mat-card>
  <div class="button-container">
      <button mat-button class="btn cancel-button" (click)="closePopup()">{{'POPUP_ORGANIZATION.CLOSE_BUTTON' | translate}}</button>
  </div>
</div>
