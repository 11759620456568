import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {

  @Input() options: { value: string, display: string }[];
  @Input() defaultValue: string;
  @Input() label: string;
  @Output() selectedValue: EventEmitter<string> = new EventEmitter<string>();

  myControl = new FormControl('');
  filteredOptions: Observable<{ value: string, display: string }[]>;

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    if (this.defaultValue) {
      const defaultOption = this.options.find(option => option.value === this.defaultValue);
      if (defaultOption) {
        this.myControl.setValue(defaultOption.display);
      }
    }
  }

  private _filter(value: string): { value: string, display: string }[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.display.toLowerCase().includes(filterValue));
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectedValue.emit(event.option.value)
  }

}
