<mat-card *ngIf="data">

  <div class="column col-12 card-header-container">
      <div>
          <mat-card-title>
            {{data.display ? data.display : '-'}}
          </mat-card-title>
      </div>
  </div>

  <div class="scroll-container">
    <mat-card-content class="column col-12 mt-2">
      <p>
        <b>Sistema:</b>
        {{data.system ? data.system : '-'}}
      </p>

      <p>
        <b>Codi:</b>
        {{data.code ? data.code : '-'}}
      </p>

      <p *ngIf="data.valueQuantity">
        <b>Valor:</b>
        {{data.valueQuantity.value ? data.valueQuantity.value : '-'}} {{data.valueQuantity.unit ? data.valueQuantity.unit : '-'}}
      </p>

      <p>
        <b>Data de la mesura:</b>
        {{data.effectiveDateTime ? (data.effectiveDateTime | date:"dd/MM/yyyy HH:mm") : '-'}}
      </p>

      <p>
        <b>Rang de referència baix:</b>
        {{data.lowReferenceRange ? data.lowReferenceRange : '-'}}
      </p>

      <p>
        <b>Rang de referència alt:</b>
        {{data.highReferenceRange ? data.highReferenceRange : '-'}}
      </p>

      <div *ngIf="data.components && data.components.length">
        <div *ngFor="let component of data.components">
          <p>
            <b>{{component.display ? component.display : '-'}}:</b>
            {{component.value !== null && component.value !== undefined ? component.value : '-'}}
          </p>
        </div>
      </div>

      <div *ngIf="data.note && data.note.length">
        <div *ngFor="let note of data.note; let i = index">
          <p>
            <b>
              {{ i === 0 ? 'Nota: ' : 'Nota ' + (i + 1) + ': ' }}
            </b>
            {{note.text ? note.text : '-'}}
          </p>
        </div>
      </div>

    </mat-card-content>
  </div>
</mat-card>

<div class="button-container">
    <button mat-button class="btn cancel-button" (click)="closePopup()">{{'POPUP_ORGANIZATION.CLOSE_BUTTON' | translate}}</button>
</div>
