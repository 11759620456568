<mat-toolbar class="mat-elevation-z8">
  <div class="mat-toolbar-container">
    <div
    class="row cursor-pointer"
    mat-icon-button>
    <img [routerLink]="'/dashboard'" class="col-12" src="../../../../assets/images/logo-salut.svg" alt='Logo Salut'>
    </div>
  </div>
  <a [routerLink]="'/dashboard'">
    <img src="../../../../assets/images/m-connecta-logo.svg" alt='Logo mConnecta'>
  </a>
  <div class="icons-div">
    <mat-icon class="mc-icon cursor-pointer" aria-hidden="false" aria-label="Menu"  #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" style="z-index:1050">more_vert</mat-icon>
    <mat-menu #menu="matMenu">
      <button mat-menu-item [routerLink]="'/dashboard'">
        <span>{{ 'TOOLBAR.DASHBOARD' | translate }}</span>
      </button>
      <button mat-menu-item [routerLink]="'/organizations'">
        <span>{{ 'TOOLBAR.ORGANIZATION' | translate }}</span>
      </button>
      <button mat-menu-item [routerLink]="'/device'">
        <span>{{ 'TOOLBAR.DEVICES' | translate }}</span>
      </button>
      <button mat-menu-item [routerLink]="'/audit-event'">
        <span>{{ 'TOOLBAR.ERRORS' | translate }}</span>
      </button>
      <button mat-menu-item [routerLink]="'/service-request'">
        <span>{{ 'TOOLBAR.SERVICE_REQUEST' | translate }}</span>
      </button>
      <button mat-menu-item [routerLink]="'/query'">
        <span>{{ 'TOOLBAR.QUERY' | translate }}</span>
      </button>
    </mat-menu>
    <a class="mc-a" [routerLink]="'/dashboard'"><mat-icon class="mc-icon mr-8" aria-hidden="false" aria-label="home">home</mat-icon></a>
    <mat-icon (click)="downloadPDF()" class="mc-icon cursor-pointer" aria-hidden="false" aria-label="info">info</mat-icon>
    <mat-icon (click)="logOut()" class="mc-icon ml-8 cursor-pointer" aria-hidden="false" aria-label="logOut">power_settings_new</mat-icon>
  </div>
</mat-toolbar>
