import { Component, Input } from '@angular/core';
import { ChartData } from '../../models/chart-data.model';


@Component({
  selector: 'app-advanced-pie-chart',
  templateUrl: './advanced-pie-chart.component.html',
  styleUrls: ['./advanced-pie-chart.component.scss']
})
export class AdvancedPieChartComponent {

  @Input() view: [number, number] = [600, 300];
  @Input() chartData: ChartData[];

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = true;



  constructor() {
    Object.assign(this, this.chartData );
  }

}
