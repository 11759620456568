import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';
import { Observable, startWith, map } from 'rxjs';
import { SelectSearch } from '../../models/select-search.model';

interface Item {
  name: string;
  id: string;
}

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})

export class SelectSearchComponent implements OnInit {
  items = new UntypedFormControl([]);
  filteredItems!: Observable<Item[]>;
  searchCtrl = new UntypedFormControl('');
  conditionType = SelectSearch.CONDITION;
  @Input() itemsList: { name: string, id: string }[];
  @Input() type: SelectSearch;
  @Output() itemsSelected: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit(): void {
    this.filteredItems = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '', this.itemsList))
    );
  }

  private _filter(value: string, options: Item[]): Item[] {
    const filterValue = value.toLowerCase();
    return options.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  onSelectionChange(a: MatOptionSelectionChange): void {
    let value: Item[] = this.items?.value || ([] as Item[]);
    if (a.isUserInput) {
      if (!a.source.selected) {
        value = [];
      } else if (a.source.selected) {
        value = this.itemsList.filter((v) => v.id === a.source.value);
      }
      this.items.setValue(value);
      this.itemsSelected.emit(this.items.value);
    }
  }

  onKeydown(e: KeyboardEvent, i: HTMLInputElement) {
    i.onkeydown?.(e);
    e.stopPropagation();
  }
}
