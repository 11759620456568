import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserRepresentation } from '../models/user-representation.model';
import { JwtHelperService } from '@auth0/angular-jwt';

const _jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  baseUrl: string = environment.urlIdpApi;
  urlApp: string = environment.urlApp;
  adminOrganizationId: string = environment.adminOrganizationId;

  constructor(private _http: HttpClient) { }

  get accessToken(): string {
    return localStorage.getItem("accessToken")!;
  }

  set accessToken(token: string | null) {
    if(token == "undefined") token = null;
    localStorage.setItem('accessToken', token!);
  }

  deleteAccessToken() {
    localStorage.removeItem('accessToken');
  }

  isUserLoggedIn(): Observable<boolean>{
    if(!this.accessToken || this.accessToken == null) return of(false)
    return of(true)
  }

  isTokenExpired(): Observable<boolean> {
    if(this.decodeJWT(this.accessToken) != null && this.decodeJWT(this.accessToken).exp < Date.now()) return of(true)
    return of(false)
  }

  getRole(): Observable<any>{
    if(this.decodeJWT(this.accessToken) != null) return of(this.decodeJWT(this.accessToken).roles)
    return of(null)
  }

  authorize(tokenId: string): Observable<any> {
    return this._http.get(this.baseUrl + "temporalToken/" + tokenId)
  }

  login(key: string): void {
    window.location.href = this.baseUrl + 'auth?response_type=code&client_id=' + this.adminOrganizationId + '&redirect_uri=' + this.urlApp + '&scope=create+delete&state=' + key;
  }

  private decodeJWT(token:string): any{
    if(!token) return null;
    return new UserRepresentation(_jwtHelper.decodeToken(token));
  }
}
