

export class UserRepresentation {
    ProfessionalFirstLastName:string;
    ProfessionalId: string;
    ProfessionalSecondLastName: string;
    ProfessionalCode: string;
    ProfessionalName: string;
    EntityProvider: string;
    PlatformCodi: string;
    authorities: string;
    roles: string;
    PatientCIP: string;
    UnitProvider: string;
    ProfessionalIdType: string;
    ProfessionalType: string;
    jti: string;
    iat: string;
    iss: string;
    aud: string;
    exp: string;
  
    constructor(token:UserRepresentation) {
      this.ProfessionalFirstLastName = token.ProfessionalFirstLastName;
      this.ProfessionalId = token.ProfessionalId;
      this.ProfessionalSecondLastName = token.ProfessionalSecondLastName;
      this.ProfessionalCode = token.ProfessionalCode;
      this.ProfessionalName = token.ProfessionalName;
      this.EntityProvider = token.EntityProvider;
      this.PlatformCodi = token.PlatformCodi;
      this.authorities = token.authorities;
      this.roles = token.roles;
      this.PatientCIP = token.PatientCIP;
      this.UnitProvider = token.UnitProvider;
      this.aud = token.aud;
      this.ProfessionalIdType = token.ProfessionalIdType;
      this.ProfessionalType = token.ProfessionalType;
      this.jti = token.jti;
      this.iat = token.iat;
      this.iss = token.iss;
      this.aud = token.aud;
      this.exp = token.exp;
    }
  
  }
  