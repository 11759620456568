import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSelectorComponent } from '@app/modules/language/components/language-selector/language-selector.component';



@NgModule({
  declarations: [
    LanguageSelectorComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LanguageSelectorComponent
  ]
})
export class LanguageModule { }
