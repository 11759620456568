import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { EntryData } from '@app/modules/observation/models/observation/observation.model';

@Component({
  selector: 'mcon-popup-observation',
  templateUrl: './popup-observation.component.html',
  styleUrls: ['./popup-observation.component.scss']
})
export class PopupObservationComponent {

  constructor(
    public dialogRef: MatDialogRef<PopupObservationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EntryData,
  ) { }

  closePopup(): void {
    this.dialogRef.close();
  }
}
