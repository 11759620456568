import { Component, Input } from '@angular/core';
import { Chip } from '../../models/chip.model';

@Component({
  selector: 'app-chip-entity-selected',
  templateUrl: './chip-entity-selected.component.html',
  styleUrls: ['./chip-entity-selected.component.scss']
})
export class ChipEntitySelectedComponent {
  @Input() chip: Chip[];
}
