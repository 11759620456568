import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '@env/environment';
import { Practitioner } from '../models/practitioner.model';

@Injectable({
  providedIn: 'root'
})
export class PractitionerService {

  get accessToken(): string{
    return localStorage.getItem('accessToken')!;
  }

  practitionerSubject: ReplaySubject<Practitioner> = new ReplaySubject<Practitioner>(1);
  baseUrl : string = environment.urlBffApi;
  // practitionerId: string = environment.practitionerId;

  constructor(private _http: HttpClient) { }

  getPractitionerById(practitionerId: string): Observable<Practitioner>{
    return this._http.get<Practitioner>(`${this.baseUrl}` + "Practitioner/" + practitionerId)
  }

  getPractitioner(): Observable<Practitioner>{
    return this.practitionerSubject.asObservable();
  }



  setData(practitionerId: string): void {
    this._http.get<Practitioner>(`${this.baseUrl}` + "Practitioner/" + practitionerId
    ).subscribe((res: Practitioner) => {
      this.practitionerSubject.next(res);
    });
  }
}
