import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ExpirationGuard  {
  constructor(private _authenticationService: AuthenticationService,
    private _route: Router) {}

    canActivate(): Observable<boolean> {
      return this._authenticationService.isTokenExpired().pipe(map((result: boolean) => {
        if(result){
          return true;
        }
        else{
          this._authenticationService.deleteAccessToken()
          this._route.navigate(['/login/error']);
        return false;
      }
      }))
    }
}
