<mat-card *ngIf="data">
  <div class="column col-12 card-header-container">
    <div>
      <mat-card-title>{{ 'POPUP_INFORMATION.TITLE' | translate }}</mat-card-title>
    </div>
  </div>
  <mat-card-content class="column col-12 mt-2">
    <ng-container>
      <p>{{ data }}</p>
    </ng-container>
  </mat-card-content>
</mat-card>

<div class="button-container">
  <button mat-button class="btn cancel-button" (click)="closePopup()">{{ 'POPUP_INFORMATION.CLOSE_BUTTON' | translate }}</button>
</div>
