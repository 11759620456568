import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'popup-refresh-credentials',
  templateUrl: './popup-refresh-credentials.component.html',
  styleUrls: ['./popup-refresh-credentials.component.scss'],
})
export class PopupRefreshCredentialsComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

}
