<mat-chip-list class="chip-container">
  <mat-chip ngClass="{{c.color}}" selected [ngSwitch]="c.label" *ngFor="let c of chip">
    <b *ngSwitchCase="'prov'">
        {{'ENTITY_CHIP.ORGANIZATION_PROV' | translate}}
    </b>
    <b *ngSwitchCase="'contract'">
        {{'ENTITY_CHIP.CONTRACT' | translate}}
    </b>
    <b *ngSwitchCase="'awardee'">
        {{'ENTITY_CHIP.AWARDEE' | translate}}
    </b>
    <b *ngSwitchCase="'ep'">
        {{'ENTITY_CHIP.EP' | translate}}
    </b>
    &nbsp;{{c.entity}}
</mat-chip>
</mat-chip-list>
