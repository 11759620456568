<div class="horizontal-bar-chart-container">
  <ngx-charts-bar-horizontal
    (select)="onSelect($event)"
    [view]="view"
    [scheme]="scheme"
    [results]="chartData"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [legendTitle]="legendTitle"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel">
  </ngx-charts-bar-horizontal>
</div>
