import { Injectable } from '@angular/core';
import { Error } from '../models/error.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorsService {

  baseUrl = environment.urlBffApi;

  constructor(private _http: HttpClient) { }

  getErrors(): Observable<Error[]> {
    const url = this.baseUrl + '/test/errors';
    return this._http.get<Error[]>(url).pipe();
  }

  // getNextErrors(errorUrl: string): Observable<Error[]> {
  //   return this._http.get<Error[]>(errorUrl).pipe();
  // }
}
