<mat-card *ngIf="!isLoading">
  <div class="column col-12 card-header-container">
    <div>
      <mat-card-title>{{ 'POPUP_ERROR_CODE_LEYEND.TITLE' | translate }}</mat-card-title>
    </div>
  </div>
  <div class="scroll-container">
    <mat-card-content class="column col-12 mt-2">
      <ng-container *ngFor="let error of errors">
        <p><b>{{ error.code }}: </b>{{ error.message }}</p>
      </ng-container>
    </mat-card-content>
  </div>
</mat-card>

<div class="button-container" *ngIf="!isLoading">
  <button mat-button class="btn cancel-button" (click)="closePopup()">{{ 'POPUP_ERROR_CODE_LEYEND.CLOSE_BUTTON' | translate }}</button>
</div>
