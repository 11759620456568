<mat-card *ngIf="data">
  <mat-card-title style="margin-bottom: 16px;">{{'ERRORS.POPUP.HEADER' | translate}} </mat-card-title>
  <mat-card-content class="column col-12 mt-2">

    <div class="id-container">
      <p>
        <b>{{'ERRORS.POPUP.ID' | translate}} </b> {{data.id?.value || '-'}}
      </p>
      <mat-icon class="copy-icon" (click)="copyToClipboard(data.id?.value)" *ngIf="data.id?.value">content_copy</mat-icon>
    </div>

    <div class="id-container">
      <p>
        <b>{{'ERRORS.POPUP.SERVICE_REQUEST_ID' | translate}}</b> {{data.serviceRequestId?.value ? (data.serviceRequestId?.value.trim().toLowerCase()!='servicerequest no creat' ? data.serviceRequestId?.value : '-') : '-'}}
      </p>
      <mat-icon class="copy-icon" (click)="copyToClipboard(data.serviceRequestId?.value)" *ngIf="data.serviceRequestId?.value && data.serviceRequestId?.value.trim().toLowerCase()!='servicerequest no creat'">content_copy</mat-icon>
    </div>

      <p><b>{{'ERRORS.POPUP.SERVICE_REQUEST_HIS_ID' | translate}} </b> {{ checkServiceRequestHisIdValue(data) }}</p>

      <p><b>{{'ERRORS.POPUP.PHASE' | translate}} </b> {{data.phase?.value || '-'}}</p>

      <div [ngSwitch]="data.serviceRequestStatus?.value" style="display: flex; align-items: center;">
          <b>{{'ERRORS.POPUP.SERVICE_REQUEST_STATUS' | translate}}</b>
          <p *ngSwitchCase="'all'">Tots</p>
          <p *ngSwitchCase="'completed'">Completat</p>
          <p *ngSwitchCase="'active'">Actiu</p>
          <p *ngSwitchCase="'on-hold'">En espera</p>
          <p *ngSwitchCase="'revoked'">Revocat</p>
          <p *ngSwitchCase="'draft'">Esborrany</p>
          <p *ngSwitchCase="'unknown'">Desconegut</p>
          <p *ngSwitchDefault>-</p>
      </div>
      <p><b>{{'ERRORS.POPUP.ISSUE_DATA' | translate}} </b> {{data.errorDate?.value ? (data.errorDate?.value | date: "dd/MM/yyyy" : "+0000") : '-'}}</p>
      <p><b>{{'ERRORS.POPUP.PETITIONER_CIF' | translate}} </b> {{data.petitionerCif?.value || '-'}}</p>

      <p><b>{{'ERRORS.POPUP.AWARDEE_NAME' | translate}} </b> {{data.awardeeName?.value || '-'}}</p>
      <p><b>{{'ERRORS.POPUP.AWARDEE_CIF' | translate}} </b> {{data.awardeeCif?.value || '-'}}</p>
      <p><b>{{'ERRORS.POPUP.CIP' | translate}} </b> {{data.cip?.value || '-'}}</p>

      <div *ngIf="data.actionType?.value != 'Observació'" style="display: flex; align-items: center;">
        <b>{{'ERRORS.POPUP.UP_CODE' | translate}}</b>
        <p>{{ data.upCode?.value ? data.upCode?.value : '-'}}</p>
      </div>

      <div *ngIf="data.actionType?.value != 'Observació'" style="display: flex; align-items: center;">
        <b>{{'ERRORS.POPUP.UP_NAME' | translate}}</b>
        <p>{{ data.upName?.value ? data.upName?.value : '-'}}</p>
      </div>

      <div [ngSwitch]="data.action?.value" style="display: flex; align-items: center;">
          <b>{{'ERRORS.POPUP.ACTION' | translate}}</b>
          <p *ngSwitchCase="'C'">Creació</p>
          <p *ngSwitchCase="'R'">Consulta</p>
          <p *ngSwitchCase="'U'">Edició</p>
          <p *ngSwitchCase="'D'">Donar de baixa</p>
          <p *ngSwitchCase="'E'">Perform</p>
          <p *ngSwitchDefault>-</p>
      </div>
      <div [ngSwitch]="data.actionType?.value" style="display: flex; align-items: center;">
          <b>{{'ERRORS.POPUP.ACTION_TYPE' | translate}}</b>
          <ng-container [ngSwitch]="data.actionType?.value | lowercase">
            <p *ngSwitchCase="'200'"> Prescripció</p>
            <p *ngSwitchCase="''"> -</p>
            <p *ngSwitchDefault>{{data.actionType?.value || '-'}}</p>
          </ng-container>
      </div>
      <p><b>{{'ERRORS.POPUP.DESCRIPTION' | translate}} </b> {{data.description?.value || '-'}}</p>
      <div style="display: flex; align-items: center;">
          <b>{{'ERRORS.POPUP.ERROR_CODE' | translate}}</b>
          <p>{{data.errorCode?.value || '-'}}</p>
      </div>
      <div *ngIf="data.actionType?.value != 'Observació'" style="display: flex; align-items: center;">
          <b>{{'ERRORS.POPUP.ERROR_TYPE' | translate}}</b>
          <p>{{ data.errorType?.value ? data.errorType?.value : '-'}}</p>
      </div>
      <div *ngIf="data.migrated && data.migrated.value == 'Sí'" style="display: flex; align-items: center;">
        <b>{{ data.migrated.label }}</b>
    </div>
  </mat-card-content>
</mat-card>
<div class="button-container">
  <button *ngIf="data.serviceRequestId && data.serviceRequestId.value" mat-button class="btn new-button col-sm-4 mr-8"(click)="viewServiceRequest(data.serviceRequestId.value)">Veure la sol·licitud de servei</button>
  <button mat-button class="btn cancel-button" (click)="closePopup()">{{'ERRORS.POPUP.CLOSE_BUTTON' | translate}}</button>
</div>
