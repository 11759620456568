import { LanguageModule } from '@app/modules/language/language.module';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@app/modules/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SpinnerInterceptor } from '@app/modules/shared/interceptors/spinner.interceptor';
import { GlobalErrorHandler } from '@shared/interceptors/global-error-handler.interceptor';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AuthInterceptor } from '@shared/interceptors/auth.interceptor';
import { MaterialModule } from '@material/material.module';

//Locale
import localeCat from '@angular/common/locales/ca'
import { DatePipe, registerLocaleData } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
registerLocaleData(localeCat);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    SharedModule,
    LanguageModule,
    NgxChartsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      },
      defaultLanguage: 'cat'
    })
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'ca'
    },
    { provide: MAT_DATE_LOCALE,
      useValue: 'ca'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalErrorHandler,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./../assets/i18n/", ".json");
}
